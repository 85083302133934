import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  es: {
    translation: {
      'Quienes somos': '¿Quíenes somos?',
      'nuestro servicio': 'Nuestro servicio',
      'contac tanos': 'Contáctanos',
      'eco it': 'Ecosistemas IT',
      'somos venecia': '¡Somos Venecia!',
      'sub somos': 'Venecia IT headhunter experts, es un proveedor de talentos, donde trabajamos bajo una cultura enfocada en la transformación digital, está formada por un equipo de profesionales con grado superior de ingeniería de telecomunicaciones, master en gestión de las TICs y en gestión de proyectos, con más de 12 años de trayectoria por cuenta ajena en el sector IT, que ha decidido emprender un camino en el área de la gestión de talento humano IT.',
      'sub black venecia': 'Con la finalidad de ofrecer un servicio de headhunter de alto standing, analizando detenidamente los requerimientos de cada perfil, poniendo en práctica los conocimientos en el sector que hacen que seleccionemos a los recursos que realmente necesitas incorporar a tu equipo de trabajo.',
      'btn somos': '¡Contrata al mejor talento!',
      'ofertas lab': 'Nuestro valor añadido consiste en la plena garantía de la satisfacción de nuestros servicios a nuestros clientes.',
      'reg ist': 'REGÍSTRATE',
      'ofer tas': 'OFERTAS DE EMPLEO',
      'cap tamos': '¡Captamos al mejor talento!',
      'aviso legal': 'Aviso Legal',
      'pol cok': 'Política de cookies',
      'pol pri': 'Política de privacidad',
      'dere chos': 'Todos los derechos reservados',
      'sec tores': 'Sectores',
      'ges tion': 'Gestión',
      'des arrollo': 'Desarrollo',
      'tec no': 'Tecnologías',
      'b dd': 'Bases de Datos',
      'se g': 'Seguridad',
      'sis t': 'Sistemas',
      'ven tas': 'Ventas',
      'con vene': 'Con Venecia',
      'ven tajas': 'Ventajas',
      'ben e': 'Beneficios',
      'aho rra': '• Ahorrarás sustancialmente tiempo y coste en la búsqueda de talentos.',
      'head hunt': '• Nuestros Headhunters están altamente capacitados para captar al mejor talento de acuerdo a las necesidades de cada uno de nuestros clientes.',
      'garan tizamos': '• Garantizamos un 85% de incorporaciones de las vacantes de las que nos ocupamos.',
      'otor gamos': '• Otorgamos garantia de reemplazo de 90 días naturales de los candidatos incorporados.',
      'traba jamos': '• Trabajamos a éxito.',
      'ofre cemos': '• Ofrecemos el mejor fee fijo y competitivo del mercado.',
      'mode lo': 'Nuestro modelo de negocio está diseñado en una única modalidad de Headhunter',
      'dir eccion': 'Dirección',
      'te l': 'Teléfono',
      'env iar': 'Enviar',
      'tu nomb': ' Tu nombre',
      'me ss': 'Mensaje',
      'cob int': 'Cobertura internacional: España, Malta, Irlanda, Venezuela, Argentina.',
      't y': 'Gracias por ponerte en contacto',
      'e co': 'Ecosistemas IT',
    },
  },
  en: {
    translation: {
      'Quienes somos': 'About us',
      'nuestro servicio': 'Our service',
      'contac tanos': 'Contact us',
      'eco it': 'IT Ecosystems',
      'somos venecia': 'We are Venecia!',
      'sub somos': "Venecia IT headhunter experts, is a talent provider, where we work under a culture focused on digital transformation, it is made up of a team of professionals with a higher degree in telecommunications engineering, a master's degree in TIC's management and project management, with More than 12 years of experience as an employee in the IT sector, who has decided to embark on a path in the area of IT human talent management.",
      'sub black venecia': 'In order to offer a high standing headhunter service, carefully analyzing the requirements of each profile, putting into practice the knowledge in the sector that makes us select the resources that you really need to incorporate into your work team.',
      'btn somos': 'Hire the best talent!',
      'ofertas lab': 'Our added value consists in the full guarantee of the satisfaction of our services to our customers.',
      'reg ist': 'SIGN UP',
      'ofer tas': 'JOB OFFERS',
      'cap tamos': 'We recruit the best talent!',
      'aviso legal': 'Legal Warning',
      'pol cok': 'Cookies policy',
      'pol pri': 'Privacy policy',
      'dere chos': 'All rights reserved',
      'sec tores': 'Sectors',
      'ges tion': 'Management',
      'des arrollo': 'Development',
      'tec no': 'Technologies',
      'b dd': 'Databases',
      'se g': 'Security',
      'sis t': 'Sistems',
      'ven tas': 'Sales',
      'con vene': 'With Venecia',
      'ven tajas': 'Advantages',
      'ben e': 'Benefits',
      'aho rra': '• You will substantially save time and cost in the search for talent.',
      'head hunt': '• Our Headhunters are highly trained to attract the best talent according to the needs of each of our clients.',
      'garan tizamos': '• We guarantee 85% of incorporations of the vacancies that we take care of.',
      'otor gamos': '• We grant a 90-calendar-day replacement guarantee for incorporated candidates.',
      'traba jamos': '• We work for success.',
      'ofre cemos': '• We offer the best fixed and competitive fee in the market.',
      'mode lo': 'Our business model is designed in a single Headhunter modality',
      'dir eccion': 'Direction',
      'te l': 'Phone',
      'env iar': 'Send',
      'tu nomb': ' Your name',
      'me ss': 'Message',
      'cob int': 'International coverage: Spain, Malta, Ireland, Venezuela, Argentina.',
      't y': 'Thank you for getting in touch!',
      'e co': 'IT Ecosystems',
    },
  },
};

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
//   .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    resources,
    lng: 'es', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
