import React from 'react';
import { useTranslation } from 'react-i18next';
import './ofertaslab.css';
import analyst from '../../assets/analyst.jpg';
import picado from '../../assets/picado.jpg';

const Ofertaslab = () => {
  const { t } = useTranslation();

  return (
    <div className="regis">
      <div className="regisdiv">
        <h1 className="registitle">{t('ofertas lab')}</h1>
      </div>
      <div className="regiscards">
        <div className="regiscard">
          <div className="regiscardimgdiv">
            <img className="regiscardimg" src={picado} alt="ads" />
          </div>
          <div className="regiscardtitle">
            <h1>{t('reg ist')}</h1>
          </div>
        </div>
        <div className="regiscard">
          <div className="regiscardimgdiv">
            <img className="regiscardimg" src={analyst} alt="sdfsdf" />
          </div>
          <div className="regiscardtitle">
            <h1>{t('ofer tas')}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ofertaslab;
