import React from 'react';

import { Navbar, Footer, Ofertaslab, Somosvenecia, Convene, Carousel2, Header } from './containers';

import './App.css';
import Background from './assets/main.jpeg';

const App = () => (
  <div className="App">
    <Navbar />
    <div
      className="bg"
      style={{ background: `url(${Background})` }}
    >
      <Header />
    </div>
    <Somosvenecia />
    <Ofertaslab />
    <Carousel2 />
    <Convene />
    <Footer />
  </div>
);

export default App;
