import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';

import './footer.css';
import ig from '../../assets/ig.png';
import tw from '../../assets/tw.png';
import ws from '../../assets/was.png';
import fb from '../../assets/facebook.png';
import sh from '../../assets/shield.png';
import candado from '../../assets/cand.png';
import logo from '../../assets/logo_V.png';

const Footer = () => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const form = useRef();

  const { t } = useTranslation();

  const sendEmail = (e) => {
    e.preventDefault();

    setIsFormSubmitted(true);
    emailjs.sendForm('service_wqkho1j', 'template_nlx1fb9', form.current, '7MX-u2uZxMrirz1oP');
  };

  return (
    <div className="app__footer">
      <div className="topfooter" id="contactanos">
        <div className="leftfooter">

          <div className="app__footer-cards" />
          {!isFormSubmitted ? (
            <div className="app__footer-form app__flex">
              <form ref={form} onSubmit={sendEmail}>
                <div className="riw">
                  <label htmlFor="cliente" className="ruw">
                    <input type="radio" name="opcion" id="cliente" value="opcion" />
                    <h4 className="lbl">Cliente</h4>
                  </label>
                  <label htmlFor="cliente" className="ruw">
                    <input type="radio" name="opcion" id="candidato" value="opcion" />
                    <h4 className="lbl">Candidato</h4>
                  </label>

                </div>
                <div className="app__flex">
                  <input className="p-text" type="text" placeholder={t('tu nomb')} name="user_name" required />
                </div>
                <div className="app__flex">
                  <input className="p-text" type="email" placeholder="Email" name="user_email" required />
                </div>
                <div>
                  <textarea
                    className="p-text"
                    placeholder={t('me ss')}
                    name="message"
                    required
                  />
                </div>
                <input type="submit" className="p-text" value={t('env iar')} id="submit" />
              </form>
            </div>
          ) : (
            <div>
              <h3 className="head-text">
                {t('t y')}
              </h3>
            </div>
          )}
        </div>

        <div className="rightcontact">
          <div>
            <h1>{t('contac tanos')}</h1>
          </div>
          <div>
            <h3>{t('dir eccion')}</h3>
            <h4>Alameda del Valle, 28051 - Madrid</h4>
          </div>
          <div>
            <h3>Email</h3>
            <h4>info@veneciaitheadhunterexperts.com</h4>
          </div>
          <div>
            <h3>{t('te l')}</h3>
            <h4>+34 635 748 362</h4>
          </div>
        </div>
      </div>
      <div className="bottomfooter">
        <div>
          <h1>{t('cap tamos')}</h1>
        </div>
        <div className="redes">
          <a href="http://google.com">
            <img src={ig} alt="ig" />
          </a>
          <a href="https://twitter.com/VeneciaIt">
            <img src={tw} alt="tw" />
          </a>
          <a href="https://wa.me/+34635748362">
            <img src={ws} alt="was" />
          </a>
          <a href="https://www.facebook.com/VeneciaItheadhunter/">
            <img src={fb} alt="fb" />
          </a>
        </div>
        <div>
          <h2>{t('cob int')}</h2>
        </div>
      </div>
      <div className="avisolegal">
        <div className="flex-column">
          <div className="img-legal">
            <img src={sh} alt="aaa" />
          </div>
          <h2>{t('aviso legal')}</h2>
        </div>
        <div className="flex-column">
          <div className="img-legal">
            <img src={candado} alt="candado" />
          </div>
          <h2>{t('pol cok')}<br />{t('pol pri')} </h2>
        </div>
        <div className="flex-column">
          <div className="img-legal">
            <img src={logo} alt="logo" />
          </div>
          <h2>{t('dere chos')} <br /> Venecia 2023</h2>
        </div>
      </div>
    </div>
  );
};

export default Footer;
