import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logo.png';
import './navbar.css';
import esp from '../../assets/esp.png';
import usa from '../../assets/usa.png';

const Navbar = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <img src={logo} />
        </div>
        <div className="gpt3__navbar-links_container">
          <div className="orangeflag">
            <a href="#somosvenecia">
              <h3>
                {t('Quienes somos')}
              </h3>
            </a>
          </div>
          <div className="orangeflag">
            <a href="#servicio">
              <h3>
                {t('nuestro servicio')}
              </h3>
            </a>
          </div>
          <div className="orangeflag">
            <a href="#eco">
              <h3>
                {t('eco it')}
              </h3>
            </a>
          </div>
          <div className="orangeflag">
            <a href="#contactanos">
              <h3>
                {t('contac tanos')}
              </h3>
            </a>
          </div>
        </div>
      </div>

      <div className="langcontainer">
        <div className="langdiv" onClick={() => i18n.changeLanguage('es')}>
          <img src={esp} alt="esp" />
        </div>
        <div className="langdiv" onClick={() => i18n.changeLanguage('en')}>
          <img src={usa} alt="usa" />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
