import React from 'react';
import './convene.css';
import { useTranslation } from 'react-i18next';

const Convene = () => {
  const { t } = useTranslation();

  return (
    <div className="convene" id="convene">
      <h1 className="titlecon">{t('con vene')}</h1>
      <div className="roow">

        <div className="textventa">
          <h2 className="subtitlecon">{t('ven tajas')}</h2>
          <h2 className="black textcon">
            {t('aho rra')} <br />
            {t('head hunt')} <br />
            {t('garan tizamos')} <br />
          </h2>
        </div>
        <div className="textventa">
          <h2 className="subtitlecon" id="servicio">{t('ben e')}</h2>
          <h2 className="black textcon">
            {t('otor gamos')} <br />
            {t('traba jamos')} <br />
            {t('ofre cemos')} <br />
          </h2>
        </div>
      </div>
      <div>
        <h2 className="subtitlecon mode">{t('mode lo')}</h2>
      </div>
    </div>
  );
};
export default Convene;
