import React from 'react';
import { useTranslation } from 'react-i18next';
import './carousel.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';

import ges from '../../assets/gestion.jpg';
import tablet from '../../assets/tablet.jpg';
import tendencias from '../../assets/tendencias.jpg';
import lupa from '../../assets/lupa.jpg';
import team from '../../assets/team.jpg';
import bg2 from '../../assets/bg2.jpg';
import trust from '../../assets/trust.jpg';
import bdd from '../../assets/bdd.jpg';
import sec from '../../assets/sec.jpg';
import gestio from '../../assets/gestion-de-carpetas.png';
import equipo from '../../assets/equipo.png';
import datos from '../../assets/base-de-datos.png';
import ventas from '../../assets/grafico-de-barras.png';
import proteger from '../../assets/proteger.png';

function Carousel2() {
  const { t } = useTranslation();

  return (
    <div className="slider" id="eco">
      <h1 className="titleeco">{t('e co')}</h1>
      <Carousel>
        <Carousel.Item interval={200000}>
          <CardGroup>
            <Card style={{ backgroundColor: '#FF7E20' }}>
              <Card.Img variant="top" src={ges} />
              <Card.Body>
                <Card.Title>{t('ges tion')}</Card.Title>
                <Card.Text>
                  <p>CIO</p>
                  <p>CTO</p>
                  <p>Head of Infrastructure</p>
                  <p>It Direcctor </p>
                  <p>It Manager</p>
                  <p>PMO</p>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ backgroundColor: '#FF7E20' }}>
              <Card.Img variant="top" src={tendencias} />
              <Card.Body>
                <Card.Title>{t('des arrollo')}</Card.Title>
                <Card.Text>
                  <p>DevOps Engineer   |   Back-End Developer</p>
                  <p>Front-End Developer  |  Full-Stack</p>
                  <p>Scrum Master  |  Product Owner</p>
                  <p> Agile Coach   |   Project Manager</p>
                  <p>Software Architect  |  Mobile Developer</p>
                  <p>QA/Tester  |  Product Manager</p>
                  <p>UX/UI Designer</p>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ backgroundColor: '#FF7E20' }}>
              <Card.Img variant="top" src={tablet} />
              <Card.Body>
                <Card.Title>{t('tec no')}</Card.Title>
                <Card.Text>
                  <p>Java  |  Phyton</p>
                  <p>.NET  |  C#</p>
                  <p>Javascript  |  PHP</p>
                  <p>Angular</p>
                  <p>Swift</p>
                  <p>Ruby  |  SQL</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <CardGroup>
            <Card style={{ backgroundColor: '#FF7E20' }}>
              <Card.Img variant="top" src={bdd} />
              <Card.Body>
                <Card.Title>{t('b dd')}</Card.Title>
                <Card.Text>
                  <p>DB2</p>
                  <p>Oracle</p>
                  <p>PostgreSQL</p>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ backgroundColor: '#FF7E20' }}>
              <Card.Img variant="top" src={trust} />
              <Card.Body>
                <Card.Title>Data & Analytics</Card.Title>
                <Card.Text>
                  <p>Digital Analytics</p>
                  <p>Data Scientis</p>
                  <p>Data Architect</p>
                  <p>Data Engineer</p>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ backgroundColor: '#FF7E20' }}>
              <Card.Img variant="top" src={team} />
              <Card.Body>
                <Card.Title>ERP</Card.Title>
                <Card.Text>
                  <p>SAP</p>
                  <p>ABAP</p>
                  <p>Otros ERP</p>
                  <p>Oracle</p>
                  <p>MS Dynamics</p>
                  <p>Salesforces</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </Carousel.Item>

        <Carousel.Item interval={5000}>
          <CardGroup>
            <Card style={{ backgroundColor: '#FF7E20' }}>
              <Card.Img variant="top" src={sec} />
              <Card.Body>
                <Card.Title>{t('se g')}</Card.Title>
                <Card.Text>
                  <p>Cybersecurity</p>
                  <p>Security Specialist</p>
                  <p>Security Architects</p>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ backgroundColor: '#FF7E20' }}>
              <Card.Img variant="top" src={bg2} />
              <Card.Body>
                <Card.Title>{t('sis t')}</Card.Title>
                <Card.Text>
                  <p>Cloud Architect</p>
                  <p>Cloud Systems Administrator Network</p>
                  <p>SysOps</p>
                  <p>Systems Administrators</p>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ backgroundColor: '#FF7E20' }}>
              <Card.Img variant="top" src={lupa} />
              <Card.Body>
                <Card.Title>{t('ven tas')}</Card.Title>
                <Card.Text>
                  <p> Account Manager</p>
                  <p>Presales Manager </p>
                  <p> Sales Engineer</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </Carousel.Item>
      </Carousel>
      <h1 className="titleeco">{t('sec tores')}</h1>
      <div className="roowcaru">
        <div>
          <img className="ima" src={gestio} alt="b" />
        </div>
        <div>
          <img className="ima" src={equipo} alt="b" />
        </div>
        <div>
          <img className="ima" src={datos} alt="b" />
        </div>
        <div>
          <img className="ima" src={proteger} alt="b" />
        </div>
        <div>
          <img className="ima" src={ventas} alt="b" />
        </div>
      </div>
    </div>
  );
}

export default Carousel2;
