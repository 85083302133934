import React from 'react';
import './header.css';

const Header = () => (
  <div className="gpt3__header" id="home">

    <div className="gpt3__header-content">
      <div className="purplebox">
        <h1>IT HEADHUNTER</h1>
        <h1>EXPERTS</h1>
      </div>
    </div>

  </div>
);

export default Header;
