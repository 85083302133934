import React from 'react';
import './somosvenecia.css';
import { useTranslation } from 'react-i18next';
import working from '../../assets/workin.jpg';

const Somosvenecia = () => {
  const { t } = useTranslation();

  return (
    <div className="somosvenecia" id="somosvenecia">
      <h1 className="titlesomos">{t('somos venecia')}</h1>
      <div className="roww">
        <div className="textsomos">
          <h2 className="subtitlesomos">
            {t('sub somos')}
          </h2>

          <div className="btnsomos">{t('btn somos')}</div>
        </div>
        <div className="imgsomos">
          <img src={working} alt="team" />
        </div>
      </div>
    </div>
  );
};

export default Somosvenecia;
